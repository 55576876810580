import { MdxContent, ValidIsoDateString } from "../../domain/common";
import { Box, Card, CardContent, Grid, Typography } from "@mui/material";
import { dateTimeFormat, LocalDate } from "../common/date/LocalDate";
import { Mdx } from "../../utils/mdx/Mdx";
import * as React from "react";

interface NewsCardProps {
    mdxContent: MdxContent;
    published: ValidIsoDateString;
}

export const NewsCard = (p: NewsCardProps) => {
    return (
        <Grid item>
            <Card elevation={0}>
                <CardContent>
                    <Typography variant={"subtitle2"}>
                        <LocalDate date={p.published} format={dateTimeFormat} />
                    </Typography>
                    <Box sx={{ color: "text.secondary", typography: "body2" }}>
                        <Mdx mdxContent={p.mdxContent} />
                    </Box>
                </CardContent>
            </Card>
        </Grid>
    );
};
