import * as React from "react";
import Layout from "../../../components/org/Layout";
import { Button, Container, Grid, Typography } from "@mui/material";

import { GetServerSidePropsContext, InferGetServerSidePropsType } from "next";
import { constNull, flow, pipe } from "fp-ts/function";
import { calendar, index, newsRoute } from "../../../routes/org";
import { array, either, option, readonlyArray, task, taskEither } from "fp-ts";
import { pick, runTask } from "../../../utils/fp";
import { sequenceS } from "fp-ts/Apply";
import { supabase } from "../../../utils/supabase/init";
import { executeSupabaseQuery } from "../../../utils/supabase/useQuery";
import * as t from "io-ts";
import { newsFields } from "../../../domain/news";
import { Subdomain } from "../../../utils/subdomain/subdomain";
import { validIsoDateStringCodec } from "../../../domain/common";
import { InternalLink } from "../../../components/common/Link";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { useTranslation } from "../../../utils/i18n/lang";
import { messages } from "../../../utils/i18n/i18n";
import { columns } from "../../../utils/pg-ts/pg-ts";
import { serializeMdx } from "../../../utils/mdx/serializeMdx";
import {
    EventCard,
    EventCardFooter,
    EventCardLocationDate,
} from "../../../components/org/EventCard";
import { NewsCard } from "../../../components/org/NewsCard";
import { NearestEvent, nearestEvents } from "../../../hooks/event";

const latestNewsFields = pipe(newsFields, pick("id", "content"), (fields) => ({
    ...fields,
    published: validIsoDateStringCodec, // TODO pg-ts untyped
}));
const latestNewsCodec = t.type(latestNewsFields);
const latestNewsMaxCount = 3;

const latestNews = (orgSubdomain: Subdomain, max: number) =>
    pipe(
        executeSupabaseQuery(
            "latestNews",
            t.array(latestNewsCodec),
            (supabase) =>
                supabase
                    .from("news_published") // TODO pg-ts
                    .select(columns(latestNewsFields))
                    .eq("subdomain", orgSubdomain)
                    .range(0, max + 1)
                    .order("published", { ascending: false }),
            supabase
        ),
        taskEither.chainW(
            flow(
                array.map((news) =>
                    pipe(
                        news.content,
                        serializeMdx,
                        taskEither.map((mdxContent) => ({
                            ...news,
                            mdxContent,
                        }))
                    )
                ),
                taskEither.sequenceArray
            )
        )
    );

// TODO organization created_at is propagated to views... probaly not needed!, thi is an internal column

export const getServerSideProps = async (context: GetServerSidePropsContext) =>
    pipe(
        context.params,
        index.queryCodec.decode,
        taskEither.fromEither,
        taskEither.chainW((query) =>
            sequenceS(taskEither.ApplyPar)({
                subdomain: taskEither.right(query.subdomain), // TODO learn the fp way to add context
                latestNews: latestNews(query.subdomain, latestNewsMaxCount),
                nearestEvents: nearestEvents(query.subdomain),
            })
        ),
        task.map(
            either.foldW(
                (e) => {
                    return {
                        notFound: true as const, // as const have to be here :/
                    };
                },
                (props) => {
                    return {
                        props,
                    };
                }
            )
        ),
        runTask
    );

export default (p: InferGetServerSidePropsType<typeof getServerSideProps>) => {
    const m = useTranslation(msg);
    return (
        <Layout subdomain={p.subdomain} seo={{ title: "Jesenický šnek" }}>
            <Container maxWidth={"lg"}>
                <Grid container spacing={4} direction={"row-reverse"}>
                    <Grid
                        item
                        sm={4}
                        spacing={1}
                        container
                        direction={"column"}
                    >
                        <Grid item>
                            <Typography variant={"h5"} component={"div"}>
                                {m.news}
                            </Typography>
                        </Grid>
                        {pipe(
                            p.latestNews,
                            readonlyArray.toArray,
                            array.takeLeft(latestNewsMaxCount),
                            array.map((news) => (
                                <NewsCard key={news.id} {...news} />
                            ))
                        )}
                        {p.latestNews.length <= latestNewsMaxCount ? null : (
                            <Grid item sx={{ textAlign: "right" }}>
                                <InternalLink
                                    href={newsRoute.route({
                                        subdomain: p.subdomain,
                                    })}
                                >
                                    <Button
                                        size={"medium"}
                                        endIcon={<NavigateNextIcon />}
                                    >
                                        starší aktuality
                                    </Button>
                                </InternalLink>
                            </Grid>
                        )}
                    </Grid>
                    <Grid
                        item
                        sm={8}
                        container
                        direction={"column"}
                        spacing={3}
                    >
                        {pipe(
                            p.nearestEvents,
                            array.head,
                            option.fold(constNull, (nearestEvent) => (
                                <Grid item>
                                    <EventCard
                                        image={nearestEvent.banner_image}
                                        paddingTop={"200px"}
                                        orgSubdomain={p.subdomain}
                                        eventId={nearestEvent.id}
                                    >
                                        <Typography variant={"h3"}>
                                            {nearestEvent.title}
                                        </Typography>
                                        <EventCardLocationDate
                                            {...nearestEvent}
                                        />
                                        <EventCardFooter>
                                            <Typography>
                                                {p.nearestEvents[0].subtitle}
                                            </Typography>
                                        </EventCardFooter>
                                    </EventCard>
                                </Grid>
                            ))
                        )}

                        <Grid
                            item
                            container
                            direction={"row"}
                            spacing={2}
                            alignItems={"stretch"}
                            justifyContent={"flex-end"}
                        >
                            {pipe(
                                p.nearestEvents,
                                array.dropLeft(1),
                                array.map((event) => (
                                    <NextEventCard
                                        key={event.id}
                                        {...event}
                                        orgSubdomain={p.subdomain}
                                    />
                                ))
                            )}
                            <Grid item>
                                <InternalLink
                                    href={calendar.route({
                                        subdomain: p.subdomain,
                                    })}
                                >
                                    <Button
                                        size={"medium"}
                                        endIcon={<NavigateNextIcon />}
                                    >
                                        další závody
                                    </Button>
                                </InternalLink>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        </Layout>
    );
};
interface NextEventCardProps extends NearestEvent {
    orgSubdomain: Subdomain;
}
const NextEventCard = (p: NextEventCardProps) => (
    <Grid item md={6} flexGrow={1}>
        <EventCard
            image={p.banner_image}
            paddingTop={"150px"}
            orgSubdomain={p.orgSubdomain}
            eventId={p.id}
        >
            <Typography variant={"h4"}>{p.title}</Typography>
            <EventCardFooter>
                <EventCardLocationDate {...p} />
            </EventCardFooter>
        </EventCard>
    </Grid>
);

const msg = messages(
    {
        news: "News",
    },
    {
        cs: {
            news: "Aktuality",
        },
    }
);
