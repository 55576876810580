
    (window.__NEXT_P = window.__NEXT_P || []).push([
      "/_subdomains/[subdomain]",
      function () {
        return require("private-next-pages/_subdomains/[subdomain]/index.tsx");
      }
    ]);
    if(module.hot) {
      module.hot.dispose(function () {
        window.__NEXT_P.push(["/_subdomains/[subdomain]"])
      });
    }
  